import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Home_Assistant_Tutorials/PrimaryBox.jsx';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Home Assistant 2022.8.6 Configuration for your WQHD INSTAR MQTTv5 Broker",
  "path": "/Frequently_Asked_Question/Home_Assistant_INSTAR_WQHD_MQTTv5_Camera_Control/",
  "dateChanged": "2022-08-24",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my IN-9408 2k+ with the new Version of Home Assistant. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Home Assistant 2022.8.6 Configuration for your WQHD INSTAR MQTTv5 Broker' dateChanged='2022-08-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my IN-9408 2k+ with the new Version of Home Assistant. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Home_Assistant_INSTAR_WQHD_MQTTv5_Camera_Control/' locationFR='/fr/Frequently_Asked_Question/Home_Assistant_INSTAR_WQHD_MQTTv5_Camera_Control/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "home-assistant-202286-configuration-for-your-wqhd-instar-mqttv5-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#home-assistant-202286-configuration-for-your-wqhd-instar-mqttv5-broker",
        "aria-label": "home assistant 202286 configuration for your wqhd instar mqttv5 broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Assistant 2022.8.6 Configuration for your WQHD INSTAR MQTTv5 Broker`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation-with-docker"
        }}>{`Installation with Docker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#basic-setup"
        }}>{`Basic Setup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#turn-your-mqtt-topics-into-switches"
        }}>{`Turn your MQTT Topics into Switches`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#add-your-switches-as-ui-elements"
        }}>{`Add your Switches as UI Elements`}</a></li>
    </ul>
    <h2 {...{
      "id": "installation-with-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation-with-docker",
        "aria-label": "installation with docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation with Docker`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull homeassistant/home-assistant:latest`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/homeassistant/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` -R `}<span parentName="code" {...{
            "className": "token number"
          }}>{`775`}</span>{` /opt/homeassistant`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d --rm --privileged --net`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`host --name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'home-assistant'`}</span>{` -e `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'TZ=Europe/Berlin'`}</span>{` -v /opt/homeassistant/config:/config homeassistant/home-assistant:latest`}</code></pre></div>
    <h2 {...{
      "id": "basic-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basic-setup",
        "aria-label": "basic setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic Setup`}</h2>
    <p>{`Open your browser on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8123/onboarding.html`}</code>{` and create a user account to log in:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/4d383/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABXElEQVQoz3XQy07CUBAG4NMeIJpoNGoL5SIUzqWlFxBkAaYEJDHGxLgADGzZmLg0oRd0ob6GK5+DttEG42uZQiJlweQs5zv/zADW8tbPnDMvP3vdidi6Pn94S2nNTK2Ta/QEuX5w/bj7+puwXSbSD6IYmnMwW/DN24Ze7vXHZwqt62pVU6Qsd9y8A88L1pzDKIYb2AX2d7o7rpWJTKleqaqanheLhTR/dNEHThC33B3HX+OY7cVtf42dIGUM6ypVtApCCGNMqCSJWc4YAieAy+T/PJBw/ITjM1HcHlZlRCSZrkqS5WKOM+5XOLpmmAxtj93EOhERoXhZ4TfFU74dYtZ02e0HC3HSGCgojyklGBNUImKhnOOTxiBMtly4DbNWeLCTzqioayVJRRiXFF00bmjrkruaAPsLWm5sG47bLjMLDo1RJi8KRBMKONno8dNP4eljf/zOTDdmhpb3B4cyq2H54w9RAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fbef055baa2e6acc1205a4934ce4fc7b/e4706/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 230w", "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/d1af7/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 460w", "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/7f308/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 920w", "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/7842e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 1195w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fbef055baa2e6acc1205a4934ce4fc7b/a0b58/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 230w", "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/bc10c/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 460w", "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/966d8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 920w", "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/2ccb3/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 1195w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fbef055baa2e6acc1205a4934ce4fc7b/81c8e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 230w", "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/08a84/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 460w", "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 920w", "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/4d383/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 1195w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fbef055baa2e6acc1205a4934ce4fc7b/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
              "alt": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "title": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Along the way we can directly install the `}<strong parentName="p">{`MQTT Addon`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cfc67f3069899f30367f7510423dcdda/d43b4/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA5klEQVQY033QzWvCMBgG8CSdlzKYOG1tUpP6mcZ+DRU8DFYGDjyapINdd9jfkQyW/d2jVeZNeHhPz48HXoDUt6cslBaeDGhc7/Wrf+9n9ZGuRBiMMCHjCD/2H3r1J2wcPJm2Ka3XXQClRdJ6yqIO+88f0Wiw2uyTJY8JwbjF4XDg7zVo3J0y5z66YtVhaYD+jbaHTZmlqSiLvCiKacIoY0tGwqoG2kF5WYb/+Jx2Wbtg95ZXT7NqN+XrGRcLkc1FzlMebg9A/aBb+N3h9bEMeLKYx/EkntA2lFE8HpYvQDskzeVBHf4DX6xA7vtpdEUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cfc67f3069899f30367f7510423dcdda/e4706/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 230w", "/en/static/cfc67f3069899f30367f7510423dcdda/d1af7/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 460w", "/en/static/cfc67f3069899f30367f7510423dcdda/7f308/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 920w", "/en/static/cfc67f3069899f30367f7510423dcdda/76dce/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 1202w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cfc67f3069899f30367f7510423dcdda/a0b58/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 230w", "/en/static/cfc67f3069899f30367f7510423dcdda/bc10c/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 460w", "/en/static/cfc67f3069899f30367f7510423dcdda/966d8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 920w", "/en/static/cfc67f3069899f30367f7510423dcdda/ccc09/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 1202w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cfc67f3069899f30367f7510423dcdda/81c8e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 230w", "/en/static/cfc67f3069899f30367f7510423dcdda/08a84/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 460w", "/en/static/cfc67f3069899f30367f7510423dcdda/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 920w", "/en/static/cfc67f3069899f30367f7510423dcdda/d43b4/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 1202w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cfc67f3069899f30367f7510423dcdda/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
              "alt": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "title": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And connect it to our camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTTv5 Broker`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9deda635d41159b69d98b967f6e8721b/187fa/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABaklEQVQoz3WQUW+CMBSF2+rjNBoVFaUCpYUigpjswTnN4nycCa3bw/7JtheKbv97AdS46E5OmtuHL+fcC6BQSChYOknB7qcerok18u8XlmlalmXZZDTo1UkERAYSBRKFpEIyg0IBeCaP8HctXOOh3tf7XS1XR+t2W42aHQGhKvKYVL434PrkSdf7ba3XLtTRNK3VaJAIJCmSFzWFAkioqsxdlMlbDeOVT8nQwBhjwzAMjF3qDOgYbT+g3EOR/oFzy9M/Uf3wIfCYx33OuVfI9xj2QvRyBZ+n4xpir0+XceB7nNOjGCX2kAVo+wmLO1XlFQxP8GC6HDPHoawUIU40CdzZHFwko9uwzJND7lLmljBljLt05Mdo+/Vv7XOyHq+iMXeYSwuSMuZSB/vTAs6gSNHN2kikUB4G4TykFiUWIyYjJrVNnxE7iMuDVaSq7rKbySl6Pdw9vjfDVXO2acbPpRvxprZ4QxfblvAvPz6SVMhsF/QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9deda635d41159b69d98b967f6e8721b/e4706/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 230w", "/en/static/9deda635d41159b69d98b967f6e8721b/d1af7/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 460w", "/en/static/9deda635d41159b69d98b967f6e8721b/7f308/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 920w", "/en/static/9deda635d41159b69d98b967f6e8721b/e78a4/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 1194w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9deda635d41159b69d98b967f6e8721b/a0b58/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 230w", "/en/static/9deda635d41159b69d98b967f6e8721b/bc10c/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 460w", "/en/static/9deda635d41159b69d98b967f6e8721b/966d8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 920w", "/en/static/9deda635d41159b69d98b967f6e8721b/de1dc/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 1194w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9deda635d41159b69d98b967f6e8721b/81c8e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 230w", "/en/static/9deda635d41159b69d98b967f6e8721b/08a84/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 460w", "/en/static/9deda635d41159b69d98b967f6e8721b/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 920w", "/en/static/9deda635d41159b69d98b967f6e8721b/187fa/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 1194w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9deda635d41159b69d98b967f6e8721b/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
              "alt": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "title": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "turn-your-mqtt-topics-into-switches",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#turn-your-mqtt-topics-into-switches",
        "aria-label": "turn your mqtt topics into switches permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Turn your MQTT Topics into Switches`}</h2>
    <p>{`If you followed the installation path described above you will now be able to find the main configuration file in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/homeassistant/config/configuration.yaml`}</code>{`. Here we can add an import for `}<strong parentName="p">{`Switch`}</strong>{` items that we want to use to toggle our camera state through the MQTT API:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# MQTT`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# switch: !include mqtt/switches.yaml /deprecated`}</span>{`
mqtt: `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`include mqtt/switches.yaml`}</code></pre></div>
    <p>{`Now create the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqtt`}</code>{` folder next to the configuration file and create the file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`switches.yaml`}</code>{` inside:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` mqtt
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` mqtt/switches.yaml`}</code></pre></div>
    <p>{`Here we can create, for example, 4 switches that can toggle the privacy masks in our camera. If your set your camera to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`prefix`}</code>{` `}<em parentName="p">{`cameras`}</em>{` and the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqtt ID`}</code>{` `}<em parentName="p">{`115`}</em>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/75f3e79a589db914960043849556cd3d/6f2be/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAByElEQVQoz2WSz2/SYByH+QMGyJBCoWsp26S05TQzl0pLC33ftsBY+7Zv38Kq7MdBjcmMRjITk8WLiRdj4sV5Ek8e/CdNqV0wPnmuT755P3lz3W5XlmVJksRO21h8GL37bVytjFc//3M1uf4Fnn+GtiNLYoNhmnwzJ2UI7Qfjs+v4/Y9w+S1c3m74PfHt7eJmhV5+ksWD3eY+x3Bso5XEoihKktRut4GhzYOpP7W9CUx1R+BkZCY6Aw8hyxpTBYbebvL1PbrMb1wWBABggEPkBzgkhEQBxiSaLc7Ozy8u4ydPQ0IcZ1Ivt+rbPFPdbdzPYlEUk8sARhHBOEAZruseH0/Ga2zbNoeAvsdX8ixd5uvlf2MIrTg+JYRgjP01AABFUY7WKIpi6INaoVnJs7USVytxufTBWQzn8/lmbNt2XzdUTdN6PV1TB/qQo/epAkvl2WqR+zvYXTybzcIwRAh5nosQciyzrzzUHj861PXDgaEPzD1GqBbZylYW3w0GISSE+L6PE0Lso/DyTbT8Gr+4+Tj1vnhBbI2oEk9tsdU8VytyuU6nk34SQRBUVU1WMU2QYg7ByRyevnbQxbO+caUPxz1th2uxDJ/6B2LsmILUexCNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75f3e79a589db914960043849556cd3d/e4706/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 230w", "/en/static/75f3e79a589db914960043849556cd3d/d1af7/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 460w", "/en/static/75f3e79a589db914960043849556cd3d/7f308/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 920w", "/en/static/75f3e79a589db914960043849556cd3d/59649/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 1029w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/75f3e79a589db914960043849556cd3d/a0b58/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 230w", "/en/static/75f3e79a589db914960043849556cd3d/bc10c/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 460w", "/en/static/75f3e79a589db914960043849556cd3d/966d8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 920w", "/en/static/75f3e79a589db914960043849556cd3d/faf06/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 1029w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75f3e79a589db914960043849556cd3d/81c8e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 230w", "/en/static/75f3e79a589db914960043849556cd3d/08a84/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 460w", "/en/static/75f3e79a589db914960043849556cd3d/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 920w", "/en/static/75f3e79a589db914960043849556cd3d/6f2be/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 1029w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/75f3e79a589db914960043849556cd3d/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
              "alt": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "title": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can use the following configuration file - feel free to replace the used used MQTT topics with any topic from our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`MQTTv5 API`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`sensor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`device`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`identifiers`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in8415_office
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`manufacturer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` INSTAR Deutschland GmbH
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`model`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` INSTAR 2k+ IN`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`8415 WLAN
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` IN`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`8415 2k+ Office
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`configuration_url`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.201:80"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`availability`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/connection
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_available`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"online"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_not_available`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"offline"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in8415_office_testament
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in8415_office_testament
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` IN`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`8415 LWT
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/connection
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_alarmserver
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Alarmserver
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/alarm/triggered
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`device_class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` current
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`switch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_red_alarm_area
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Red Alarm Area
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/alarm/areas/red/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/alarm/areas/red/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_blue_alarm_are
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Blue Alarm Area
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/alarm/areas/blue/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/alarm/areas/blue/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_green_alarm_are
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Green Alarm Area
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/alarm/areas/green/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/alarm/areas/green/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_yellow_alarm_area
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Yellow Alarm Area
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/alarm/areas/yellow/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/alarm/areas/yellow/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_privacy_mask_1
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Privacy Mask 1
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/privacy/region1/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/privacy/region1/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_privacy_mask_2
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Privacy Mask 2
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/privacy/region2/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/privacy/region2/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_privacy_mask_3
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Privacy Mask 3
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/privacy/region3/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/privacy/region3/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_privacy_mask_4
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Privacy Mask 4
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/privacy/region4/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/privacy/region4/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_auto_gamma
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Auto Gamma
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/gamma/auto
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/gamma/auto
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_auto_denoise
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Auto Denoise
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/denoise/auto
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/denoise/auto
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_flip
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Flip
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/transform/flip
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/transform/flip
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_mirror
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Mirror
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/transform/mirror
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/transform/mirror
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`button`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_alarm_trigger
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Alarm Trigger
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/alarm/pushalarm
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_press`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`alarm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`light
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_reboot
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Reboot
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` system/reboot/now
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_press`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`cog`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`refresh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`outline
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_brightness
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Brightness
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_brightness
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`brightness`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/brightness
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/brightness
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unit_of_measurement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'%'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_contrast
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Contrast
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_contrast
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`contrast`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`box
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/contrast
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/contrast
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unit_of_measurement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'%'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_saturation
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Saturation
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_saturation
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`palette`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`outline
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/saturation
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/saturation
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unit_of_measurement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'%'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_hue
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Hue
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_hue
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`palette`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`outline
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/hue
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/hue
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`360`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_sharpness
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Sharpness
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_sharpness
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`shark`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`fin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`outline
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/sharpness
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/sharpness
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unit_of_measurement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'%'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_vibrancy
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Vibrancy
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_vibrancy
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`palette`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`outline
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/vibrancy/value
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/vibrancy/value
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`255`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_gamma
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Gamma
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_gamma
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`gamma
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/gamma/preset
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/gamma/preset
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_denoise
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Denoise
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_denoise
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`contrast`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`box
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/denoise/preset
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/denoise/preset
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_max_iso
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Max ISO
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_isomax
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`brightness`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/multimedia/image/isomax
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/multimedia/image/isomax
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`32`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_auto_ir
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Auto IR LEDs
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_autoled
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`lightbulb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`night`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`outline
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/features/nightvision/autoled
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/features/nightvision/autoled
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_auto_ircut
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Auto IRcut
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_autoircut
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`lightbulb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`night`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`outline
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/features/nightvision/autoircut
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/features/nightvision/autoircut
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_red_alarm_area_sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Red Alarm Area Sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_red_area_sense
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`cctv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`off
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/alarm/areas/red/sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/alarm/areas/red/sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_blue_alarm_area_sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Blue Alarm Area Sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_blue_area_sense
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mdi:cctv-off'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/alarm/areas/blue/sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/alarm/areas/blue/sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_green_alarm_area_sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Green Alarm Area Sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_blue_area_green
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`cctv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`off
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/alarm/areas/green/sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/alarm/areas/green/sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`unique_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` office_yellow_alarm_area_sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Office Yellow Alarm Area Sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`object_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` in9408_blue_area_yellow
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mdi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`cctv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`off
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/status/alarm/areas/yellow/sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/201/alarm/areas/yellow/sensitivity
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{{ value_json.val }}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"{{ value }}"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`step`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span></code></pre></div>
    <p><strong parentName="p">{`Note`}</strong>{` that you need to add both the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/#status-command-or-raw-topics"
      }}>{`Command and State Topic`}</a>{`. By setting the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`optimistic`}</code>{` parameter to `}<em parentName="p">{`false`}</em>{` each switch will update the command topic when toggled but will only actually switch after your camera returns the state update - signifying that the command was received and executed.`}</p>
    <h2 {...{
      "id": "add-your-switches-as-ui-elements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-your-switches-as-ui-elements",
        "aria-label": "add your switches as ui elements permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add your Switches as UI Elements`}</h2>
    <p>{`Now that we have our switches defined we need to add them to our dashboard. Start by enabling the `}<em parentName="p">{`Edit`}</em>{` mode:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/76823/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.130434782608692%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAq0lEQVQI12XLS27DIBRAUa/ABgeCH9/3oTjBJlEm6f53VrWDSlWvzvRO7aMevY9xEuGWufbXNcoKuAKaQP9dPNnAgLuNMtHxlvNT7i9swzow9rpeTC6IxG7zGwTwfxBL22+P55OlTvV2cushYSpsjFHLotQiUsd4IBYsJaX4K+dMRB4gBO+cm45+J8TgfYzhe1ZKa203nwrte2NmAPA/AUApRUS01vM8K6W+AKQgG36hxSmuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d2cafa1919f726f6d0377ade8cb5cb03/e4706/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 230w", "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/d1af7/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 460w", "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/7f308/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 920w", "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/e6db5/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 1038w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d2cafa1919f726f6d0377ade8cb5cb03/a0b58/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 230w", "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/bc10c/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 460w", "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/966d8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 920w", "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/6a022/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 1038w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d2cafa1919f726f6d0377ade8cb5cb03/81c8e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 230w", "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/08a84/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 460w", "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 920w", "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/76823/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 1038w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d2cafa1919f726f6d0377ade8cb5cb03/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
              "alt": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "title": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Create a new dashboard tab:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/84523bd0a24162276eeda109e6773ca2/0d1a4/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABQ0lEQVQoz23N2VLCMAAF0D4qpWIDTWnSdElo0n2DcURtWYuCD/joj/gd/rIjDFKVM3fu250radoAY2RZlomxgcndenffvE5my6ze5HWTVU1Wn5NW6/Hipdq+Vbt9udhI0BKQcJ0wzbCGNkse5vF0Xs6aSb1IprOsWmVPq/Rx2U5WrbP6Oa0aCerIMIkGhyoYUDaKkySM4zhO8jyP4th2HGLZPzGJ3et18XgfvX962w8JIQMhNBj0AQCu6xbl2PcDX4gwDDnnGCPcghBSurKiQmCMbqElQV0nhOi6DlTVdlmUlmzkUUrZgdtCKSWEKMqNLF/LnSu5cy1hjE3ThBAen9M08TyPfzv2mRDCcRxFUbonEjrQNA0AQCktyzKKIn5JEASu6/4a908AAI7jhGEohPAu4Zz/fW6PGWNFUQgh/Ev+P38BETFKL1HMM8gAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84523bd0a24162276eeda109e6773ca2/e4706/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 230w", "/en/static/84523bd0a24162276eeda109e6773ca2/d1af7/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 460w", "/en/static/84523bd0a24162276eeda109e6773ca2/7f308/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 920w", "/en/static/84523bd0a24162276eeda109e6773ca2/97bad/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 1036w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/84523bd0a24162276eeda109e6773ca2/a0b58/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 230w", "/en/static/84523bd0a24162276eeda109e6773ca2/bc10c/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 460w", "/en/static/84523bd0a24162276eeda109e6773ca2/966d8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 920w", "/en/static/84523bd0a24162276eeda109e6773ca2/fcad6/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 1036w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84523bd0a24162276eeda109e6773ca2/81c8e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 230w", "/en/static/84523bd0a24162276eeda109e6773ca2/08a84/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 460w", "/en/static/84523bd0a24162276eeda109e6773ca2/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 920w", "/en/static/84523bd0a24162276eeda109e6773ca2/0d1a4/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 1036w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/84523bd0a24162276eeda109e6773ca2/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_06.png",
              "alt": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "title": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And click on `}<strong parentName="p">{`Add Card`}</strong>{` to add a new element:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e6b03cfdafeded87073cd9aa14ba0204/42de8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABK0lEQVQY012Ly07CQABFu8OgM7RTQJ12aKedMqUthoUJJkajoUUFJfEV7MNopSUEjCYGN/6GP+PnGboSb07u4uRegTct13E8z6WUnl5H9/Pl5WPajya9MPOjLCjox3kQrfDD7CZ/D18/HxbLIMoF6nVNr2vYHcr3jkbj4fNilM4u4pe7fD5M0sHT7Hb6djJOe+HEL+jH2VkyPU+mfjgRrFabWjYmOtEM3NBxQyfUpIxZnBuMUdZs2fa+5+iGuUO0YlA00bBGBc6bDUJUrKiKgpAEIajAVQAAEEIRQrxdD9oWqVe3ABArsAIBLJdAuQQ2NwTOuWmaiqKoqirLsiiKkiRJCK1RrUlIXnlRRHLNi78PPn7sqy/BdV3GGMZ47fw/fyVSOwP9MNl1jn8BdYo/QPHHAIUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e6b03cfdafeded87073cd9aa14ba0204/e4706/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 230w", "/en/static/e6b03cfdafeded87073cd9aa14ba0204/d1af7/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 460w", "/en/static/e6b03cfdafeded87073cd9aa14ba0204/7f308/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 920w", "/en/static/e6b03cfdafeded87073cd9aa14ba0204/e8a68/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 1033w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e6b03cfdafeded87073cd9aa14ba0204/a0b58/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 230w", "/en/static/e6b03cfdafeded87073cd9aa14ba0204/bc10c/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 460w", "/en/static/e6b03cfdafeded87073cd9aa14ba0204/966d8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 920w", "/en/static/e6b03cfdafeded87073cd9aa14ba0204/de74f/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 1033w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e6b03cfdafeded87073cd9aa14ba0204/81c8e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 230w", "/en/static/e6b03cfdafeded87073cd9aa14ba0204/08a84/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 460w", "/en/static/e6b03cfdafeded87073cd9aa14ba0204/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 920w", "/en/static/e6b03cfdafeded87073cd9aa14ba0204/42de8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 1033w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e6b03cfdafeded87073cd9aa14ba0204/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_07.png",
              "alt": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "title": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you need to select `}<strong parentName="p">{`Entities`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d54ccfd27950b8b89492e78dd0b709e9/12bff/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABt0lEQVQoz1WRyY7UMBBAc2GZaVBn8VLlpePsm92dhKalOQFCaEYg8Qt8A18xJyRu/C6K3cPyVIcq209VJUeMEkQoPFVd23kdj7Od1+m0jsfFLef5fHHLuZtc0fZVN9T92I727uPD+uEhYodeHKppmqy11ll3PG3ZVk7jOFRlGcKYXGslEAUiAh+Wt8v7+4hwYcpmXi/utLrTatc7t1zccXHzm8nNRVGaojDGdF03DEPpqaqKAY+TJEIAk+fOOWtd1/VN07Rt2zTX6P6hba9l3/eUwc3tq4hzrjxSSqnU6OfXWqsnpJRCiK5tmroSQkilJLL63bfi0/fIK4oQEsfxPt4naZIkaexJ05RzDgFdbOHhnFHM91RFwsM5l1IeDlrpLXxjyRgDgK0U/NnXX8+//NQClVIoRBzvd7cvoyzLGGOUUgDIPcbkxhhEzLJsk5VWEl98/nFz/6iUDHKSJLvd7ioTQvae156Qh7G3waTkUnOphd8fAP6TpZTb/wGUZVnXNQAgore21wjY1FVVlQjXwzRNrzKlVAhx8IT/CHlogh7t+VP+lcPOYe2gIWI4JISEK+KhT4SxfwPdi1UiEiDSjQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d54ccfd27950b8b89492e78dd0b709e9/e4706/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 230w", "/en/static/d54ccfd27950b8b89492e78dd0b709e9/d1af7/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 460w", "/en/static/d54ccfd27950b8b89492e78dd0b709e9/7f308/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 920w", "/en/static/d54ccfd27950b8b89492e78dd0b709e9/4416e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 1032w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d54ccfd27950b8b89492e78dd0b709e9/a0b58/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 230w", "/en/static/d54ccfd27950b8b89492e78dd0b709e9/bc10c/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 460w", "/en/static/d54ccfd27950b8b89492e78dd0b709e9/966d8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 920w", "/en/static/d54ccfd27950b8b89492e78dd0b709e9/6ed01/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 1032w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d54ccfd27950b8b89492e78dd0b709e9/81c8e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 230w", "/en/static/d54ccfd27950b8b89492e78dd0b709e9/08a84/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 460w", "/en/static/d54ccfd27950b8b89492e78dd0b709e9/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 920w", "/en/static/d54ccfd27950b8b89492e78dd0b709e9/12bff/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 1032w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d54ccfd27950b8b89492e78dd0b709e9/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_08.png",
              "alt": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "title": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And add all switches:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` entities
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entities`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` switch.privacy_mask_1
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` switch.privacy_mask_2
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` switch.privacy_mask_3
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` switch.privacy_mask_4
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`title`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Privacy Mode
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/12bff/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABx0lEQVQ4y4XSXW+bMBQGYK5HspaPJNjGPraJD/gAaSDLklab1miqNu1if2L/YP//Ykpo13wo7aNzA+j1awPBZDJhjDnnEFEB9A9fN9++Lzebqv98OWW3btb397unLz9/948/gqmqJsIiYtM0VVURUdM06BxnTHB+OZwxIl9/2jYPu2CWMcaF1rooCgBQAwBtjDZ7+mKUUnF0Ox6PAs4Y55yItttt0zRlWVbvQcQkU+OYB7PZjHOO6BZtW9c1IpbHcP8u/huWdnPrdn/M098gl1KwzNbrsl3Xfn9m/QKU9KtHantflUSEiAAwPIqTdDT6GGQHc4eV91prcUoqJaXMXww3lVJRdBuGH4I4jtM0dfP50CmEyI+dXea5lBIAoigKwzBIkiRNU+99ezgzAIiLwHHzkD8JA0BzgIjuOkS01gJAHMevYa1113V93xORv25Y3Rhz3tx13Wq18t6/8YWJaPiXzpuXy2XXdVVVldcRkXPutXk6nXLOjTFD+N3mk3CWMcaYVPLubrFYtP5NRGStyYV43vZ0kmTZLM9VMa9MgcYW5jpbOKVtnqvnZr/9pUArMFxaLk3GRZbNsisYlywHnsubm5swHP0DuKSGvg24094AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/e4706/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 230w", "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/d1af7/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 460w", "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/7f308/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 920w", "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/4416e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 1032w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/a0b58/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 230w", "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/bc10c/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 460w", "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/966d8/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 920w", "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/6ed01/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 1032w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/81c8e/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 230w", "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/08a84/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 460w", "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 920w", "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/12bff/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 1032w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8b0348dae46f3ebd7fe8c7821d7ba4d6/c0255/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_09.png",
              "alt": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "title": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save your dashboard and try it out:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/7f725f8af2ad1d8ad79ff9ecae50fc60/Home_Assistant_with_INSTAR_MQTTv5_WQHD_Cameras_10.gif",
        "alt": "Home Assistant mit Ihrem WQHD INSTAR MQTTv5 Broker"
      }}></img></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      